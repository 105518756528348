import { useEffect } from "react";
import { $sessionId } from "@/shared/model/session";

export const useSaveGameResult = (points: number) => {
  useEffect(() => {
    if (!points || points === 0) return;

    const sessionId = $sessionId.getState();
    if (!sessionId) {
      console.error("No session ID found. Cannot update score.");
      return;
    }

    async function saveResult() {
      try {
        const response = await fetch('/api/game/updateScore.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Session ${sessionId}`,
          },
          body: JSON.stringify({ points }),
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Failed to update score:", errorText);
          return;
        }

        const data = await response.json();
        if (data.error) {
          console.error("Error from backend updating score:", data.message);
        } else {
          console.log("Score updated successfully:", data.payload);
        }
      } catch (err) {
        console.error("Network or other error when updating score:", err);
      }
    }

    saveResult();
  }, [points]);
};