import { useState, useEffect } from 'react';
import { $sessionId } from "@/shared/model/session";

interface UseGetInviteesResult {
  invitees: number;
  score: number;
  loading: boolean;
  error: string | null;
}

interface GameDataResponse {
  error: boolean;
  payload: {
    friends: number;
    score: number;
  } | null;
}

export const useGetInvitees = (): UseGetInviteesResult => {
  const [invitees, setInvitees] = useState<number>(0);
  const [score, setScore] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchInvitees = async () => {
      setLoading(true);
      setError(null);

      const sessionId = $sessionId.getState(); // Retrieve session ID

      if (!sessionId) {
        setError('No session ID found.');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch('/api/game/gameData.php', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Session ${sessionId}`,
          },
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error("API Error:", errorText);
          setError(`API Error: ${response.status} ${response.statusText}`);
          setLoading(false);
          return;
        }

        const data: GameDataResponse = await response.json();

        if (data.error || !data.payload) {
          setError('Failed to fetch invitees.');
        } else {
          setInvitees(data.payload.friends);
          setScore(data.payload.score);
        }
      } catch (err) {
        console.error("Fetch Error:", err);
        setError('An unexpected error occurred.');
      } finally {
        setLoading(false);
      }
    };

    fetchInvitees();
  }, []);

  return { invitees, score, loading, error };
};