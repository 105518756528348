import React from "react";
import AvatarImg from '@/shared/assets/images/main/avatar.svg';
import coinImage from '@/shared/assets/images/main/coin.svg'; 
import { Steps, useNavigatorModel } from "@/shared/model";
import { randModel } from "@/shared/model/rang";
import { clickerModel } from "@/features/clicker/model";

export const Home = () => {
  console.log("[Home] Component start rendering.");

  const rank = randModel.useRang();
  const { stepChanged } = useNavigatorModel();
  const { value } = clickerModel.useClicker();

  console.log("[Home] Before rendering, rank:", rank, "value:", value);

  const startGame = () => {
    console.log("[Home] Start game button clicked.");
    stepChanged(Steps.GAME);
  };

  const formattedValue = new Intl.NumberFormat().format(value);
  console.log("[Home] Render UI with formattedValue:", formattedValue);
  console.log("[Home] Inside return of Home");

  return (
    // Make this container fill the entire screen space like ClickerField did
    <div 
      className="absolute top-0 left-0 w-full h-full z-[10] flex items-center justify-center"
      style={{ pointerEvents: 'none' }} // If needed, remove if you need interactions
    >
      {/* Your content container */}
      <div className="flex flex-col space-y-10 text-center relative z-[10]" style={{ pointerEvents: 'auto' }}>
        <div className="flex flex-col relative z-[10]">
          <img src={AvatarImg} alt="User Avatar" className="w-10 h-10 rounded-full mx-auto" />
          <div className="mt-2 font-mono h-4">
            {rank || "No Rank"}
          </div>
        </div>

        <div className="relative">
          <p 
            className="text-[2rem] font-mono font-bold h-12 flex items-center justify-center"
            style={{
              background: '-webkit-linear-gradient(white, #38495a)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              lineHeight: '150%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '90%',
              margin: '0 auto',
            }}
          >
            {formattedValue}
            <img 
              src={coinImage} 
              alt="coin" 
              style={{ marginLeft: '8px', width: '32px', height: '32px' }} 
            />
          </p>

          <p>Help protect your device from threats.</p>
          <p>Join the protection squad!</p>
        </div>

        <button 
          onClick={startGame} 
          className="relative z-[10] px-5 py-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
        >
          Start the game
        </button>
      </div>
    </div>
  );
};