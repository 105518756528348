import { useHapticFeedback } from "@tma.js/sdk-react";
import { useGetInvitees } from "./hooks/useGetInvitees";
import { FallingViruses } from "./scenes/FallingViruses";

export const useCreateFallingVirusesScene = (
  callbackFunction: (score: number) => void,
) => {
  const haptics = useHapticFeedback();
  const inviteesResult = useGetInvitees();

  const scoreMultiplier = inviteesResult.invitees > 0 ? inviteesResult.invitees + 1 : 1;

  return class extends FallingViruses {
    constructor() {
      super(callbackFunction, scoreMultiplier, haptics);
    }
  };
};