import React, { useEffect } from "react";
import Avatar from '@/shared/assets/images/main/avatar.svg';
import './backGround.css';
import styles from './Main.module.scss';
import { Home } from "./home";
import { useAuth } from "@/features/auth/useAuth";
import { useTelegram } from "@/shared/lib/hooks/useTelegram";
import { randModel } from "@/shared/model/rang";
import { IonIcon } from '@ionic/react';
import { giftOutline } from 'ionicons/icons';

export const Main = () => {
    console.log("[Main] Component start rendering.");

    const authModel = useAuth();
    const { userName } = authModel; 
    const rank = randModel.useRang(); 
    const { isValidPlaform } = useTelegram();

    useEffect(() => {
        console.log("[Main] useEffect triggered with isValidPlaform:", isValidPlaform);
        if (isValidPlaform) {
            console.log("[Main] Valid platform detected. Initializing authentication.");
            authModel.initialize()
                .then(() => {
                    console.log("[Main] Authentication initialized successfully. userName:", authModel.userName, "rank:", rank);
                })
                .catch((error) => {
                    console.error("[Main] Authentication initialization failed:", error);
                });
        } else {
            console.log("[Main] Invalid platform. Skipping authentication initialization.");
        }
    }, [isValidPlaform, authModel, rank]);

    const handleAirdropClick = () => {
        console.log("[Main] Airdrop button clicked.");
        alert("Coming Soon");
    };

    console.log("[Main] Rendering Main component, userName:", userName, "rank:", rank);

    console.log("[Main] Before rendering header and Home");

    console.log("[Main] Rendering Background");

    console.log("[Main] Rendering Home");

    console.log("[Main] Render complete");

    return (
        <div className={styles.root}>
            <div className="w-[90%] max-w-lg mx-auto rounded-lg p-4 px-4 flex items-center justify-between text-white mt-6 shadow-lg" 
                style={{ background: "radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%)" }}>
                <div className="flex items-center space-x-3">
                    <img src={Avatar} alt="User Avatar" className="w-10 h-10 rounded-full" />
                    <div className="text-left">
                        <span 
                            className="font-bold" 
                            style={{ 
                                background: "linear-gradient(115deg, #62cff4, #2c67f2)", 
                                WebkitBackgroundClip: "text", 
                                color: "transparent" 
                            }}
                        >
                            {userName || "Player"}
                        </span>
                        <div className="text-sm flex items-center space-x-2">
                            <h3 
                                className="font-bold" 
                                style={{ 
                                    background: "linear-gradient(white, #38495a)", 
                                    WebkitBackgroundClip: "text", 
                                    color: "transparent" 
                                }}
                            >
                                {rank || "No Rank"}
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="flex items-center space-x-2 fixed-buttons">
                    <button 
                        className="flex items-center text-white font-bold px-3 py-1.3 rounded-full" 
                        style={{ background: "linear-gradient(115deg, #62cff4, #2c67f2)" }} 
                        onClick={handleAirdropClick}
                    >
                        <IonIcon icon={giftOutline} className="mr-2" />
                        Airdrop
                    </button>
                </div>
            </div>
            <Background />           
            <Home />      
        </div>
    );
};

const Background = () => {
    console.log("[Background] Rendering background component.");
    return (
        <div className={styles.background}>
            <div id="stars"></div>
            <div id="stars2"></div>
        </div>
    );
};