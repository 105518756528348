import React, { useEffect, useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { useTelegram } from "@/shared/lib/hooks/useTelegram";
import { RouterView } from './router';
import { SocketProvider } from './socketProvider';
import { SDKProvider } from "@tma.js/sdk-react";

import './App.css';

interface UrlConfig {
    manifestUrl: `${string}://${string}`;
    twaReturnUrl: `${string}://${string}`;
}

function App() {
    const { expand } = useTelegram();
    const [urlConfig, setUrlConfig] = useState<UrlConfig | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        expand();

        fetch('/api/game_data.php?type=urls')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch configuration from backend');
                }
                return response.json();
            })
            .then((data: any) => {
                if (
                    typeof data.manifestUrl === 'string' &&
                    /^https?:\/\/.+/.test(data.manifestUrl) &&
                    typeof data.twaReturnUrl === 'string' &&
                    /^https?:\/\/.+/.test(data.twaReturnUrl)
                ) {
                    setUrlConfig({
                        manifestUrl: data.manifestUrl,
                        twaReturnUrl: data.twaReturnUrl
                    });
                } else {
                    throw new Error('Invalid configuration data received');
                }
            })
            .catch(error => {
                console.error("Error loading configuration:", error);
                setError(error.message);
            });
    }, [expand]);

    if (error) {
        return <div>Error loading configuration: {error}</div>;
    }

    if (!urlConfig) {
        return <div>Loading configuration...</div>;
    }

    return (
        <SDKProvider>
            <TonConnectUIProvider 
                manifestUrl={urlConfig.manifestUrl}
                actionsConfiguration={{
                    twaReturnUrl: urlConfig.twaReturnUrl
                }}
            >
                <SocketProvider>
                    <BrowserRouter>
                        <RouterView />
                    </BrowserRouter>
                </SocketProvider>
            </TonConnectUIProvider>
        </SDKProvider>
    );
}

export default App;