import { useLocation, useNavigate } from "react-router-dom";
import { useSaveGameResult } from "./game/hooks/saveResult";
import { Steps, useNavigatorModel } from "@/shared/model";

export const Result = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { stepChanged } = useNavigatorModel();

  const points = location.state?.score || 0;
  useSaveGameResult(points);

  const showSuccess = points >= 50;

  const handleInviteFriends = () => {
    stepChanged(Steps.FRENS);
    navigate("/Main");
  };

  const handleStartNewGame = () => {
    stepChanged(Steps.GAME);
    navigate("/Main", { state: { score: 0 } });
  };

  return (
    <main className="justify-center mx-auto p-10 h-screen flex items-center">
      <div className="absolute inset-0 -z-10 h-full w-full items-center px-5 py-24 [background:radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%)]"></div>
      <div className="flex flex-col space-y-2 text-center">
        <div className="text-3xl">{points} points</div>

        <div>
          <div className="text-sm">
            {showSuccess ? (
              <>
                <p>
                  You've managed to protect your device from threats on your
                  own!
                </p>
                <p>Good luck with that, but there is a better way</p>
              </>
            ) : (
              <>
                <p>
                  It's better to rely on professionals when <br /> it comes to
                  protecting your device from threats.
                </p>
              </>
            )}
          </div>
        </div>

        <div className="flex flex-col space-y-2">
          <button
            onClick={handleInviteFriends}
            className="px-5 py-3 text-sm font-medium text-center hover:text-white text-white bg-blue-700 rounded-lg cursor-pointer hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
          >
            Invite new friends
          </button>
          <button
            onClick={handleStartNewGame}
            className="px-5 py-3 text-sm font-medium text-center hover:text-white text-white bg-blue-700 rounded-lg cursor-pointer hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
          >
            Start new game
          </button>
        </div>
      </div>
    </main>
  );
};